<template>
  <div>
    <CRow>
      <CCol
        lg="12"
        sm="1"
      >
        <CTableWrapper
          :items="alertTypes"
        >
          <template #header>
            <div class="d-flex justify-content-between">
              Tipos de alerta
            </div>
          </template>
        </CTableWrapper>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import CTableWrapper from "../../components/AlertsTypesTable.vue";
import {getAlertTypeData, checkUser} from '../../utils/utils';
export default {
  name: "AlertsTypeTable",
  components: {
    CTableWrapper
  },
  data: () => ({
    alertTypes: [],
  }),
  created() {
    if (!checkUser()) return;
    this.receiveData();
  },
    methods: {
    receiveData() {
      this.getAlertsTypes();
    },
    /**
     * Función en la que se obtienen los tipos de alerta y que también
     * llama el metodo receiveData para cargarlas.
     * 
     * @returns {object[]} Los tipos de alerta obtenidos.
     */
    getAlertsTypes() {
      let alertsTypesData = getAlertTypeData();
      alertsTypesData.then((result) => {
        if (localStorage.token == undefined) {
          return;
        }
        let data = [];
        result.alert_types.forEach(alertTypes => {
          let element = {};
          element.alert_type_id = alertTypes.alert_type_id;
          element.alert_type_name = alertTypes.alert_type_name;
          data.push(element);
        });
        this.alertTypes=data;
      });
    },
  },
};
</script>

<style>
</style>